
import pic1 from '../../../../images/blog/approvis/pipeline.webp';
import pic2 from '../../../../images/blog/approvis/valve.webp';
import pic3 from '../../../../images/blog/approvis/flash.webp';
import pic4 from '../../../../images/blog/approvis/coude45.webp';

import pic6 from '../../../../images/blog/approvis/tube.webp';
import pic7 from '../../../../images/blog/approvis/bride.webp';
import pic8 from '../../../../images/blog/approvis/vanne.webp';

import pic10 from '../../../../images/blog/approvis/etanch.webp';
import pic11 from '../../../../images/blog/approvis/chicago.webp';
import pic12 from '../../../../images/blog/approvis/compair.webp';
import pic13 from '../../../../images/blog/approvis/echaffaudage.webp';
import pic14 from '../../../../images/blog/approvis/coffrage.webp';


export const fournitures = [
    {
        id: 0,
        pic: ` ${pic1}`,
    },
    {
        id: 1,
        pic: ` ${pic2}`,
    },
    {
        id: 2,
        title: "Brides et Tiges Filetées",
        pic: ` ${pic3}`,
    },
    {
        id: 3,
        title: "Equipement Speciaux",
        pic: ` ${pic4}`,
    },
    {
        id: 4,
        pic: ` ${pic6}`,
    },
    {
        id: 5,
        title: "Etanchéité",
        pic: ` ${pic10}`,
    }

];

export const produits = [
    {
        title: "Tubes et Raccords",
        pic: ` ${pic6}`,
    },
    {
        title: "Brides et Tiges Filetées",
        pic: ` ${pic7}`,
    },
    {
        title: "Vannes",
        pic: ` ${pic8}`,
    },
    {
        title: "Etanchéité",
        pic: ` ${pic10}`,
    }

];
export const datas = [
    {
        id: 0,
        title: "Compresseur",
        pic: ` ${pic12}`,
        text: "Nous sommes en mesure de vous offrir tout produits du CompAir et de Gardner Denver. Notre société s'occupe de la vente de compresseurs à vis,  compresseurs à piston, comme  ainsi que des pièces pour compresseurs d'air. Le service est fourni par des employés qualifiés..",
    },
    {
        id: 1,
        title: "Groupe électrogène ",
        pic: ` ${pic11}`,
        text: "La section principes et technologies vous permettra de vous familiariser avec le côté pratique de nos produits. Vous aurez le savoir-faire et la confiance nécessaires pour faire passer votre activité au niveau supérieur. Votre expérience dans l'utilisation de nos outils et équipements est essentielle pour le développement de nos produits et confirmation de notre succès. Notre mission est d'assurer et d'optimiser votre productivité..",
    },
    {
        id: 2,
        title: "Echaffaudage ",
        pic: ` ${pic13}`,
        text: "Les échafaudages peuvent s’avérer être la meilleure solution en termes de sécurité de travail en hauteur étant donné que ce sont des protections collectives efficaces et ergonomiques.",
    },
    {
        id: 3,
        title: "Coffrage Métallique",
        pic: ` ${pic14}`,
        text: "Les panneaux de coffrage mixtes permettent de composer de façon rapide des coffrages de voiles et de poteaux de dimensions différentes.",
    },
];