import React, { Component } from "react";
import Slider from "react-slick";
import construction_Metallique from "../../images/about/pic6.webp";
import photovoltaique from "../../images/about/photovoltaic.webp";
import petrole from "../../images/about/pic7.webp";
import civil from "../../images/about/pic9.webp";
import estate from "../../images/about/estate.webp";
import green from "../../images/about/green1.webp";
import modularUnit from "../../images/about/modular_unit.png";
import management_services from "../../images/about/management_services.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const content1_fr = [
  {
    icon: "flaticon-robot-arm",
    text: "Construction Métallique",
  },
  {
    icon: "flaticon-fuel-station",
    text: "Oil & Gaz",
  },
  {
    icon: "flaticon-engineer-1",
    text: "Génie-Civil",
  },
  {
    icon: "flaticon-engineer-1",
    text: "Projets Immobiliers",
  },
  {
    icon: "flaticon-factory",
    text: "Énergie verte et technologie",
  },
  {
    icon: "flaticon-home",
    text: "Cabines et Conteneurs",
  },
  {
    icon: "flaticon-robot-arm",
    text: "Structure photovoltaïque",
  },
  {
    icon: "flaticon-engineer-1",
    text: "Services de Gestion",
  },
];
const content1_en = [
  {
    icon: "flaticon-robot-arm",
    text: "Metallic construction",
  },
  {
    icon: "flaticon-fuel-station",
    text: "Oil & Gas",
  },
  {
    icon: "flaticon-engineer-1",
    text: "Civil Engineering",
  },
  {
    icon: "flaticon-engineer-1",
    text: "Real estate projects",
  },
  {
    icon: "flaticon-factory",
    text: "Green energy and technology",
  },
  {
    icon: "flaticon-home",
    text: "professional Cabins and Containers",
  },
  {
    icon: "flaticon-robot-arm",
    text: "Photovoltaic Structure",
  },
  {
    icon: "flaticon-engineer-1",
    text: "Management Services",
  },
];

const content2_fr = [
  {
    image: construction_Metallique,
    shortTitle: "Votre satisfaction est notre objectif.",
    title: "La construction métallique est notre métier.",
    subTitle:
      "Souplesse et réactivité seront les maîtres mots de nos engagements.",
    description:
      "Le savoir-faire acquis par la société SYPHEMCO lui permet de répondre aux besoins les plus spécifiques de ses clients. Reconnu dans le domaine de la charpente métallique et habitués à travailler avec des donneurs d’ordres exigeants, l'établissements SYPHEMCO occupe une place prépondérante dans la réalisation de bâtiments, du plus simple au plus complexe.",
  },
  {
    image: petrole,
    shortTitle: "Votre satisfaction est notre objectif.",
    title: "La fabrication et l'installation des pipelines.",
    subTitle:
      "Nous nous engageons à fournir des solutions industrielles sûres.",
    description:
      "SYPHEMCO détient un groupe dynamique de divers entreprise opérant dans ce secteur. SYPHEMCO est impliqué dans tous les aspects en amont et en aval des activités pétrolières et gazières et se concentre sur la fourniture de l'optimum résultat pour ses clients. Ingénierie, fabrication et installation de la tuyauterie et des cuves de process.  ",
  },
  {
    image: civil,
    shortTitle: "Votre satisfaction est notre objectif.",
    title: "Études et réalisation des ouvrages de bâtiments et du Génie Civil",
    subTitle:
      "Nous nous engageons à fournir des travaux de qualité en adéquation directe avec les exigences du monde moderne.",
    description:
      "SYPHEMCO offre à ses clients des services d’ingénierie de construction, précisément des études de conception, des études de réalisation et d'élaboration des plans d'exécution des ouvrages de bâtiments et du Génie Civil.",
  },
  {
    image: estate,
    shortTitle: "Votre satisfaction est notre objectif.",
    title: "Promotion Immobilière",
    subTitle:
      " La maîtrise des meilleurs sites ainsi que  la collaboration avec des architectes de renom sont nos atouts majeurs.",
    description:
      "SYPHEMCO est profondément ancré dans le développement de propriétés de luxe. Nous sommes constamment à la recherche d'idées nouvelles et innovantes.",
  },
  {
    image: green,
    shortTitle: "Votre satisfaction est notre objectif.",
    title: "Énergie verte et technologie",
    subTitle: "",
    description:
      "Nous sommes très conscients de l'importance des entreprises industrielles exigeant de laisser une empreinte douce sur notre environnement et le monde. Les équipes Énergie verte et Technologie, sont axées sur les résultats et les objectifs, avec la technologie la plus avancée et éprouvée pour toutes les tailles d'énergie solaire et  les projets alternatifs de production d'électricité et les constructions d'infrastructures, pour      climats et conditions difficiles du désert.",
  },
  {
    image: modularUnit,
    shortTitle: "Votre satisfaction est notre objectif.",
    title: "Construction de cabines et de conteneurs professionnels",
    subTitle: "",
    description:
      "Conception, fabrication et installation de bâtiments modulaires préfabriqués, qui ont de nombreuses utilisations différentes telles que : installation sur site, maison préfabriquée, bureaux, salle de réunion, loge de gardien, bloc sanitaire, base de vie, cabine d'habitation, etc.",
  },
  {
    image: photovoltaique,
    shortTitle: "Votre satisfaction est notre objectif.",
    title: "Structure photovoltaïque",
    subTitle: "",
    description:
      "SYPHEMCO est spécialisée dans la construction de supports solaires pour les projets photovoltaïques de petite, moyenne et grande envergure. Nous fabriquons des supports photovoltaïques en structure métallique galvanisée à chaud, des systèmes de montage et de fixation pour les panneaux solaires, sur le toit, des crochets, des rails en aluminium, des supports muraux, au sol ou sur un mât pour l'installation de vos panneaux photovoltaïques",
  },
  {
    image: management_services,
    shortTitle: "Your satisfaction is our goal.",
    title: "Services de gestion",
    subTitle: "",
    description:
      "Notre expertise réside dans la navigation précise des réclamations potentielles. De l'identification des sources et de l'établissement des événements aux évaluations méticuleuses et aux négociations stratégiques, nous assurons une approche complète. Notre accent sur les stratégies proactives de prévention et d'atténuation des réclamations nous distingue, garantissant une gestion informée et efficace des réclamations pour vos projets.",
  },
];
const content2_en = [
  {
    image: construction_Metallique,
    shortTitle: "Your satisfaction is our goal.",
    title: "Metal construction is our business.",
    subTitle:
      "Flexibility and responsiveness will be the key words of our commitments.",
    description:
      " The know-how acquired by SYPHEMCO allows it to meet the most specific needs of its customers. Recognized in the field of metal structures and used to working with demanding clients, the SYPHEMCO establishment occupies a major place in the construction of buildings, from the simplest to the most complex.",
  },
  {
    image: petrole,
    shortTitle: "Your satisfaction is our goal.",
    title: "Manufacturing and installation of pipelines.",
    subTitle: "We are committed to providing safe industrial solutions.",
    description:
      "SYPHEMCO owns a dynamic group of various companies operating in this sector. SYPHEMCO is involved in all upstream and downstream aspects of oil and gas activities and is focused on providing the optimum result for its customers. Engineering, manufacturing and installation of piping and process vessels.",
  },
  {
    image: civil,
    shortTitle: "Your satisfaction is our goal.",
    title: "Studies and realization of building and civil engineering works",
    subTitle:
      "We are committed to providing quality work directly in line with the requirements of the modern world.",
    description:
      "SYPHEMCO offers its clients construction engineering services, specifically design studies, construction studies and the development of execution plans for building and civil engineering works.",
  },
  {
    image: estate,
    shortTitle: "Your satisfaction is our goal.",
    title: "Real Estate Promotion",
    subTitle:
      "The control of the best sites as well as the collaboration with renowned architects are our major assets.",
    description:
      "SYPHEMCO is deeply rooted in the development of luxury properties. We are constantly on the lookout for new and innovative ideas.",
  },
  {
    image: green,
    shortTitle: "Your satisfaction is our goal.",
    title: "Green energy and technology",
    subTitle: "",
    description:
      "We are very aware of the importance of industrial companies demanding to leave a soft imprint on our environment and the world. The Green Energy and Technology teams are results and goal oriented, with the most advanced and proven technology for all sizes of solar energy and alternative power generation projects and infrastructure construction, for climates and harsh desert conditions.",
  },
  {
    image: modularUnit,
    shortTitle: "Your satisfaction is our goal.",
    title: "Construction of professional cabins and containers",
    subTitle: "",
    description:
      "Design, manufacture and installation of prefabricated modular buildings, which have many different uses such as: installation on site, prefabricated house, offices, meeting room, caretaker's lodge, sanitary block, living base, cabin housing, etc.",
  },
  {
    image: photovoltaique,
    shortTitle: "Your satisfaction is our goal.",
    title: " Photovoltaic Structure",
    subTitle: "",
    description:
      "SYPHEMCO company specializes in the construction of solar supports for small, medium and large photovoltaic projects. We manufacture photovoltaic supports in hot-dip galvanized metal structure, mounting and ﬁxing systems for solar panels, on the roof, hooks, aluminium rails, motorhome, wall support, on the ground or on a mast for the installation of your photovoltaic panels.",
  },
  {
    image: management_services,
    shortTitle: "Your satisfaction is our goal.",
    title: "Management Services ",
    subTitle: "",
    description:
      "Our expertise lies in navigating potential claims with precision. From identifying sources and establishing events to meticulous assessments and strategic negotiations, we ensure a comprehensive approach. Our focus on proactive claims prevention and mitigation strategies sets us apart, guaranteeing informed and effective claims management for your projects.",
  },
];

class DualSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const settings2 = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };

    return (
      <>
        <Slider
          {...settings}
          className=" project-list"
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
        >
          {this.props.lang === "fr"
            ? content1_fr.map((item, id) => (
                <div
                  key={id}
                  className="item"
                  onClick={(e) => this.slider2.slickGoTo(id)}
                >
                  <div className="project-owbx">
                    <i className={item.icon}></i>
                    <h4 className="title">{item.text}</h4>
                  </div>
                </div>
              ))
            : content1_en.map((item, id) => (
                <div
                  key={id}
                  className="item"
                  onClick={(e) => this.slider2.slickGoTo(id)}
                >
                  <div
                    className="project-owbx"
                    style={{ height: "150px!important" }}
                  >
                    <i className={item.icon}></i>
                    <h4 className="title">{item.text}</h4>
                  </div>
                </div>
              ))}
        </Slider>

        <Slider
          {...settings2}
          className="primary"
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
        >
          {this.props.lang === "fr"
            ? content2_fr.map((item, id) => (
                <div key={id} className="item">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 m-b30">
                      <div className="our-story">
                        <span>{item.shortTitle}</span>
                        <h2 className="title">{item.title}</h2>
                        <h4 className="title">{item.subTitle}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 m-b30">
                      <img
                        src={item.image}
                        className="radius-sm"
                        alt="syphemco"
                      />
                    </div>
                  </div>
                </div>
              ))
            : content2_en.map((item, id) => (
                <div key={id} className="item">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 m-b30">
                      <div className="our-story">
                        <span>{item.shortTitle}</span>
                        <h2 className="title">{item.title}</h2>
                        <h4 className="title">{item.subTitle}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 m-b30">
                      <img
                        src={item.image}
                        className="radius-sm"
                        alt="syphemco"
                      />
                    </div>
                  </div>
                </div>
              ))}
        </Slider>
      </>
    );
  }
}
export default DualSlider;
