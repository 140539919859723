import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import Aos from "aos";


import ScrollToTop from '../../element/ScrollToTop';
import Footer from '../../layout/Footer/Footer';
import Header from '../../layout/Header/Header';

import bg from '../../../images/banner/bnr1.webp';


import { datas } from './Data'
import { useTranslation } from "react-i18next";

const DeveloppementDurable = ({ lang, SetLang }) => {

    const [t, i18n] = useTranslation('traduction');
    useEffect(() => {
        Aos.init({
            duration: 1500,
            disable: 'mobile',
            once: true,
            mirror: false
        });
        i18n.changeLanguage(lang);

    }, [i18n, lang]);

    return (<>
        <Header lang={lang} SetLang={SetLang} />
        <div className="page-content bg-white">
            <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{t('Développement_Durable.Head.li2')}</h1>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li><Link to="/">{t('Développement_Durable.Head.li')}</Link></li>
                                <li>{t('Développement_Durable.Head.li2')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



            <div className="section-full bg-gray content-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className=" clearfix" data-aos="zoom-in-up">
                                <h4>
                                    {t('Développement_Durable.welcome.title')}
                                </h4>
                            </div>
                            <div className="dlab-separator-outer ">
                                <div className="dlab-separator text-primary style-icon"><i className="fa fa-leaf"></i></div>
                            </div>
                            <p className="text-left" >
                                {t('Développement_Durable.welcome.definition')}
                            </p>
                        </div>
                        <div className="section-content"></div>
                    </div>
                </div>
            </div>




            <div className="content-area">
                <div className="container">

                    {datas.map((data, id) => (

                        <div key={id} className="blog-post blog-md clearfix">

                            <div className="dlab-post-title" >
                                <h4 className="post-title" data-aos="fade-right"><a href='/'>{t("Développement_Durable.datas." + data.id + ".title")}</a></h4>
                                <div className="dlab-separator-outer ">
                                    <div className="dlab-separator bg-primary style-skew"></div>
                                </div>
                            </div>
                            <div className="dlab-post-media dlab-img-effect zoom" data-aos="fade-up-right" >
                                <a href="blog-single"><img src={data.pic} alt="syphemco" /></a>
                            </div>
                            <div className="dlab-post-info">
                                <div className="dlab-post-text" data-aos="zoom-in">
                                    <p>{t("Développement_Durable.datas." + data.id + ".text")}</p>
                                </div>

                            </div>
                        </div>
                    ))}


                </div>
            </div>

        </div>


        <Footer hideContactInfo displayNewsLetter lang={lang} />

        <ScrollToTop className="scroltop style2 radius " />
    </>
    )
}

export default DeveloppementDurable;