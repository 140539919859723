import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';
import logo from "../../../assets/images/logo.jpg";
import ReactCountryFlag from "react-country-flag";
import './Header.css'
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import common_fr from '../../element/traduction/fr.json';
import common_en from '../../element/traduction/en.json';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    init: 'en',
    lng: 'en',                              // language to use,
    resources: {
        en: {
            traduction: common_en               // 'common' is our custom namespace
        },
        fr: {
            traduction: common_fr
        },
    },
});


function HeaderComponent(props) {
    const { i18n } = useTranslation('common');

    return <div className="traduction" >

        <button className="button" onClick={() => { i18n.changeLanguage('fr'); props.SetLang('fr') }}>
            <ReactCountryFlag
                countryCode="FR"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}

                className={props.lang === 'fr' ? "lang_selected" : "lang_not_selected"}
                cdnSuffix="svg"
                title="Français"
            />
        </button>
        <button className="button" onClick={() => { i18n.changeLanguage('en'); props.SetLang('en') }}>
            <ReactCountryFlag
                countryCode="US"
                svg
                style={{
                    width: '2em',
                    height: '2em',

                }}
                className={props.lang === 'en' ? "lang_selected CountryFlag" : "lang_not_selected CountryFlag"}

                cdnSuffix="svg"
                title="English"
            />
        </button>
    </div>
}


const Header = (props) => {

    useEffect(() => {

        // sidebar open/close
        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');

        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');

            if (active) {
                current.classList.remove('open')
            } else {
                current.classList.add('open');
            }
        }



    }, []);




    return (
        <>


            <Sticky innerZ={999} enabled={true}>
                <header className="site-header mo-left header navstyle2">
                    <div className="sticky-header main-bar-wraper header-curve navbar-expand-lg">
                        <div className="main-bar clearfix bg-primary">
                            <div className="container clearfix">
                                <div className="logo-header mostion">
                                    <NavLink to="/"><img src={logo} alt="syphemco" /></NavLink>
                                </div>

                                <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>





                                <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                    <div className="logo-header d-md-block d-lg-none">
                                        <NavLink to="/"><img src={require("../../../assets/images/logo.png")} alt="syphemco" /></NavLink>
                                    </div>
                                    <ul className="nav navbar-nav">
                                        <li className="has-mega-menu homedemo"  >
                                            <NavLink exact to="/" activeClassName="selected">



                                                {props.lang === 'fr'
                                                    ? "Accueil"
                                                    : "Home"
                                                }

                                                <i className="fa"></i>
                                            </NavLink>
                                        </li>
                                        <li className="has-mega-menu "  >
                                            <NavLink to='/presentaion' activeClassName="selected" >
                                                {props.lang === 'fr'
                                                    ? "Présentation"
                                                    : "Presentation"
                                                }

                                                <i className="fa "></i>
                                            </NavLink>
                                        </li>

                                        <li >
                                            <NavLink to="/activites" className="has-mega-menu" activeClassName="selected">
                                                {props.lang === 'fr'
                                                    ? "Activités"
                                                    : "Activities"
                                                }
                                                <i className="fa fa-chevron-down"></i></NavLink>
                                            <ul className="sub-menu has-mega-menu" >
                                                <li className="has-mega-menu"><NavLink to="/activites/construction"  >
                                                    {props.lang === 'fr'
                                                        ? "Construction"
                                                        : "Construction"
                                                    }
                                                </NavLink></li>
                                                <li className="has-mega-menu"><NavLink to="/activites/approvisionnement" >
                                                    {props.lang === 'fr'
                                                        ? "Approvisionnement"
                                                        : "Procurement"
                                                    }
                                                </NavLink></li>

                                            </ul>
                                        </li>
                                        <li className="has-mega-menu">
                                            <NavLink to='/projets' activeClassName="selected" >
                                                {props.lang === 'fr'
                                                    ? "Projets"
                                                    : "Projects"
                                                }
                                                <i className="fa"></i></NavLink>

                                        </li>
                                        <li className="has-mega-menu"> <NavLink to='/Developpement_durable' activeClassName="selected" >
                                            {props.lang === 'fr'
                                                ? "Développement Durable"
                                                : "Sustainable development"
                                            }
                                            <i className="fa"></i></NavLink>

                                        </li>
                                        <li className="has-mega-menu">
                                            <NavLink to="/contact" activeClassName="selected">
                                                {props.lang === 'fr'
                                                    ? " Contactez-Nous"
                                                    : "Contact us"
                                                }
                                                <i className="fa"></i></NavLink>

                                        </li>
                                    </ul>

                                    <HeaderComponent SetLang={props.SetLang} lang={props.lang} />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </Sticky>

        </>
    )
}

export default Header;





