import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

import ico from '../../../images/icon/icon1.png'
import ico2 from '../../../images/icon/icon2.png'
import ico3 from '../../../images/icon/icon3.png'

import logo from '../../../assets/images/logo.jpg'




const Footer = (props) => {


    return (
        <>
            <footer className="site-footer style1">


                {props.displayNewsLetter &&

                    (<div className="dlab-newsletter">
                        <div className="container">
                            <div className="ft-contact wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                <div className="ft-contact-bx">
                                    <img src={ico2} alt="syphemco" />
                                    <h4 className="title">
                                        {props.lang === 'fr'
                                            ? "Tèlephone"
                                            : "Phone"
                                        }
                                    </h4>
                                    <p >
                                        <a href="tel: (+216) 36 36 22 15 ">(+216) 36 36 22 15</a>
                                    </p>

                                    <h4 className="title">Fax</h4>
                                    <p >
                                        <a href="tel: (+216) 36 36 79 33 ">(+216) 36 36 79 33</a>
                                    </p>

                                </div>
                                <div className="ft-contact-bx">
                                    <img src={ico3} alt="syphemco" />
                                    <h4 className="title">
                                        {props.lang === 'fr'
                                            ? "Adresse Email"
                                            : "E-mail adress"
                                        }
                                    </h4>
                                    <p><a href="mailto: contact@syphemco.com">contact@syphemco.com</a></p>
                                </div>
                                <div className="ft-contact-bx">
                                    <img src={ico} alt="syphemco" />
                                    <h4 className="title">
                                        {props.lang === 'fr'
                                            ? "Addresse"
                                            : "Address"
                                        }
                                    </h4>
                                    <p>
                                        {props.lang === 'fr'
                                            ? "Rue Jamel Eddine El Afghani 3ème étage, N°23,Immeuble SIBO "
                                            : "Rue Jamel Eddine El Afghani 3rd floor, N ° 23, SIBO building"
                                        }
                                    </p>
                                    <p>

                                        La poudrière 1 - 3000 Sfax, Tunisia</p>
                                </div>
                            </div>
                        </div>
                    </div>)}

                {!props.hideContactInfo &&

                    (<div className="section-full p-t50 p-b20 bg-primary text-white overlay-primary-dark footer-info-bar">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte">
                                                <span className="icon-sm"><i className="ti-location-pin"></i></span>
                                                Company Address
                                            </h5>
                                            <p className="op7">Demo address #8901 Marmora Road Chi Minh City, Vietnam</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte">
                                                <span className="icon-sm"><i className="ti-email"></i></span>
                                                E-mail
                                            </h5>
                                            <p className="m-b0 op7">info@example.com</p>
                                            <p className="op7">company@example.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte">
                                                <span className="icon-sm"><i className="ti-mobile"></i></span>
                                                Phone Numbers
                                            </h5>
                                            <p className="m-b0 op7">Mobile : +00 234 678 9012</p>
                                            <p className="op7">Phone : +00 1234 5678 90</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte">
                                                <span className="icon-sm"><i className="ti-alarm-clock"></i></span>
                                                Office Hours
                                            </h5>
                                            <p className="m-b0 op7">Mon To Sat - 08.00-18.00</p>
                                            <p className="op7">Sunday - Close</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}

                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="widget widget_about">
                                    <h4 className="footer-title">
                                        {props.lang === 'fr'
                                            ? "A Propos De L'entreprise"
                                            : "About the company"
                                        }
                                    </h4>
                                    <p>
                                        {props.lang === 'fr'
                                            ? "SYPHEMCO Investments a été fondée et formé dans le fondement de l'ingéniosité par une équipe de master business dynamique et diversifié entrepreneurs et professionnels spécialisés dans les domaines du pétrole et du gaz (amont / intermédiaire / aval), énergie verte et technologie, propriété développement et investissements."
                                            : "SYPHEMCO Investments was founded and formed in the foundation of ingenuity by a team of dynamic and diversified master's business entrepreneurs and professionals specializing in the fields of oil and gas (upstream / intermediate / downstream), green energy and technology, property development and investments."
                                        }

                                    </p>

                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="widget">
                                    <h4 className="footer-title">
                                        {props.lang === 'fr'
                                            ? "Liens Utiles"
                                            : "Useful links"
                                        }
                                    </h4>
                                    <ul className="list-arrow primary reverse rounded ">
                                        <li><Link to={"/"}>
                                            {props.lang === 'fr'
                                                ? "Accueil"
                                                : "Home"
                                            }
                                        </Link></li>
                                        <li><Link to={"/presentaion"}>
                                            {props.lang === 'fr'
                                                ? "Présentation"
                                                : "Presentation"
                                            }
                                        </Link></li>
                                        <li><Link to={"/activites"}>
                                            {props.lang === 'fr'
                                                ? "Activités"
                                                : "Activities"
                                            }
                                        </Link></li>
                                        <li><Link to={"/projets"}>
                                            {props.lang === 'fr'
                                                ? "Projets"
                                                : "Projects"
                                            }
                                        </Link></li>
                                        <li><Link to={"/Developpement_durable"}>
                                            {props.lang === 'fr'
                                                ? "Développement Durable"
                                                : "Sustainable development"
                                            }
                                        </Link></li>
                                        <li><Link to={"/contact"}>
                                            {props.lang === 'fr'
                                                ? "Contactez-nous"
                                                : "Contact us"
                                            }
                                        </Link></li>

                                    </ul>

                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="widget widget_subscribe">


                                    <div className="single-widget widget-contact">
                                        <h5 className="widget-title">
                                            {props.lang === 'fr'
                                                ? "Rendez nous visite"
                                                : "Visit us"
                                            }
                                        </h5>
                                        <div className="mapouter">
                                            <div className="gmap_canvas">
                                                <iframe width="380" height="220" id="gmap_canvas"
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d819.5811714353441!2d10.767914829213492!3d34.747412998776404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzTCsDQ0JzUwLjciTiAxMMKwNDYnMDYuNSJF!5e0!3m2!1sen!2stn!4v1617896792437!5m2!1sen!2stn"
                                                    frameBorder="0" scrolling="no" marginHeight="0"
                                                    marginWidth="0"></iframe>
                                                {/* <iframe src= width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}

                                                <a href="https://torrent9-fr.com"></a></div>

                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="footer-bottom footer-line">
                    <div className="container">
                        <div className="footer-bottom-in">
                            <div className="footer-bottom-logo"><Link to={"/"}><img src={logo} alt="" /></Link></div>
                            <div className="text-left py-1">
                                <h6> &copy; {new Date().getFullYear()} Copyright: SYPHEMCO </h6>

                                <h6>
                                    {props.lang === 'fr'
                                        ? "Conçu par :"
                                        : "Designed by :"
                                    }

                                    <a href="https://www.linkedin.com/in/mahdi-hamdi-53b8151a5/" target="toggle" >Mahdi Hamdi</a>
                                    &amp;
                                    <a href="https://www.linkedin.com/in/ramez-ben-aribia/" target="toggle">
                                        Ramez Ben Aribia
                                    </a>
                                </h6>

                            </div>

                        </div>
                    </div>
                </div>

            </footer>

        </>
    );
}


export default Footer;








