import Civil from "../../../../images/main-slider/slide1.webp"
import pipeline from "../../../../images/main-slider/pipeline.webp"
import construction from "../../../../images/main-slider/slide10.webp"


export const homeSliderContent_fr = [
    {
        image: Civil,
        title: "Génie Civil",
        description: "Grâce à un matériel très performant et grâce aux qualifications de nos collaborateurs, nous défions tous les obstacles naturels pour offrir à nos clients un accès rapide à leur lieu de travail."
    },
    {
        image: pipeline,
        title: "Maintenance & Réparation Pipeline",
        description: "SYPHEMCO dispose de vastes ressources de haute qualité pour la construction de pipelines, y compris des technologies de pointe pour livrer dans les délais, le budget et les normes les plus élevées de sécurité et de qualité."
    },
    {
        image: construction,
        title: "Construction Métallique",
        description: "Syphemco associe ainsi ses talents, ses compétences humaines et techniques nécessaires, pour que ses réalisation soient parfaites au premier jour comme au fil du temps"
    }
]

export const homeSliderContent_en = [
    {
        image: Civil,
        title: "Civil engineering",
        description: "We are committed to defying natural obstacles and providing our customers with comfortable and fast access to their workplace. For this, we use very high-performance equipment and very good quality materials guaranteeing very satisfactory compaction and lift indices."
    },
    {
        image: pipeline,
        title: "Maintenance & Repair Pipeline",
        description: "SYPHEMCO has extensive, high-quality pipeline construction resources, including advanced technologies to deliver on time, on budget, and to the highest standards of safety and quality."
    },
    {
        image: construction,
        title: "Metallic construction",
        description: "Syphemco thus combines its talents, human and technical skills necessary, so that its achievements are perfect from day one and over time."
    }
];