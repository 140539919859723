import React, { useState, useEffect } from 'react';
import ScrollToTop from '../../element/ScrollToTop';
import Footer from '../../layout/Footer/Footer';
import Header from '../../layout/Header/Header';

import Aos from "aos";


import './Contact.css'

import bg from '../../../images/background/bg5.jpg';


import { useTranslation } from "react-i18next";

import { init } from 'emailjs-com';

import { useToasts } from 'react-toast-notifications';



const Contact = ({ lang, SetLang }) => {
    init("user_xjnqPj6Tfa1nXVbz8ap2h");


    const [t, i18n] = useTranslation('traduction');

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setmessage] = useState('');

    const { addToast } = useToasts()

    const handleChangeName = (event) => {
        setName(event.target.value)
    }

    const handleChangelastName = (event) => {
        setLastName(event.target.value)
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleChangePhone = (event) => {
        setPhone(event.target.value)
    }

    const handleChangeMessage = (event) => {
        setmessage(event.target.value);

    }


    const handleSubmit = (e) => {
        e.preventDefault(); // Prevents default refresh by the browser

        const templateId = 'template_o3qia77';
        const variables = { name: name, lastName: lastName, email: email, phone: phone, message: message }
        const serviceId = 'service_ng5r8se'


        window.emailjs.send(serviceId, templateId, variables)
            .then(res => {
                addToast("Your Message has been sent Successfully ", {
                    appearance: 'success',
                    autoDismiss: true,

                })
            })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => {
                console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
                addToast("Something went wrong ", {
                    appearance: 'error',
                    autoDismiss: true,

                })
            })

        e.target[0].value = "";
        e.target[1].value = "";
        e.target[2].value = "";
        e.target[3].value = "";
        e.target[4].value = "";


    };
    useEffect(() => {
        Aos.init({
            duration: 2000,
            disable: 'mobile',
            once: true,
            mirror: false
        });
        i18n.changeLanguage(lang);

    }, [i18n, lang]);


    return (
        <>
            <Header lang={lang} SetLang={SetLang} />

            <div className="page-content bg-white">
                <div className="section-full content-inner contact-page-9 overlay-black-dark" style={{ background: "url(" + bg + ") 30% 100%" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 text-white">
                                <div className="row">

                                    <div className="col-lg-12 col-md-12 m-b30" data-aos="fade-down-right">
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-primary"><i className="ti-location-pin"></i></span>
                                                    {t("Contactez_Nous.card1.title1")}

                                                </h5>
                                                <p>
                                                    {t("Contactez_Nous.card1.description1")}
                                                </p>
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-primary">
                                                        <i className="ti-alarm-clock"></i>
                                                    </span>
                                                    {t("Contactez_Nous.card1.title2")}

                                                </h5>

                                                <p className="m-b0">{t("Contactez_Nous.card1.date")}</p>
                                                <p>{t("Contactez_Nous.card1.description2")}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6 m-b30" data-aos="zoom-in-right">
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-primary"><i className="ti-email"></i></span>
                                                    {t("Contactez_Nous.card2.title")}
                                                </h5>
                                                <p className="m-b0"><a href="mailto: contact@syphemco.com">
                                                    {t("Contactez_Nous.card2.e_mail")}
                                                </a></p>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-12 col-md-6 m-b30" data-aos="fade-up-right">
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-primary"><i className="ti-mobile"></i></span>
                                                    {t("Contactez_Nous.card3.title")}

                                                </h5>
                                                <h6>
                                                    {t("Contactez_Nous.card3.Télephone")}</h6>
                                                <p className="m-b0" >
                                                    <a href="tel: (+216) 36 36 22 15 ">(+216) 36 36 22 15</a>
                                                </p>
                                                <h6>
                                                    {t("Contactez_Nous.card3.Fax")}</h6>
                                                <p className="m-b0">
                                                    <a href="tel: (+216) 36 36 79 33 ">(+216) 36 36 79 33</a>
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="col-lg-8 col-md-12 m-b30" data-aos="fade-left" >

                                <form className="inquiry-form wow box-shadow bg-white fadeInUp" data-wow-delay="0.2s" onSubmit={handleSubmit}>
                                    <h3 className="title-box font-weight-300 m-t0 m-b10">{t("Contactez_Nous.card4.title")} <span className="bg-primary"></span></h3>

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                    <input name="firstname" type="text" required className="form-control" placeholder={t("Contactez_Nous.card4.Nom")} onChange={handleChangeName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                    <input name="lastname" type="text" required className="form-control" placeholder={t("Contactez_Nous.card4.Prénom")} onChange={handleChangelastName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                    <input name="phone" type="text" required className="form-control" placeholder={t("Contactez_Nous.card4.Téléphone")} onChange={handleChangePhone} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                    <input name="email" type="email" className="form-control" required placeholder={t("Contactez_Nous.card4.Email")} onChange={handleChangeEmail} />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                    <textarea name="message" rows="4" className="form-control" required placeholder={t("Contactez_Nous.card4.description")} onChange={handleChangeMessage}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">

                                            <button name="submit" type="submit" value="Submit" className="site-button button-xs">  <span>{t("Contactez_Nous.card4.Envoyer")} </span></button>

                                        </div>


                                    </div>
                                </form>

                            </div>


                        </div>
                    </div>
                </div>
            </div>


            <Footer hideContactInfo displayNewsLetter lang={lang} />

            <ScrollToTop className="scroltop style2 radius " />
        </>


    );
}

export default Contact;