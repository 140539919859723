import React from 'react';
import ReactDOM from 'react-dom';


import "bootstrap/dist/css/bootstrap.min.css";

import './plugins/fontawesome/css/font-awesome.min.css';
import './plugins/line-awesome/css/line-awesome.min.css';
import './plugins/flaticon/flaticon.css';
import './plugins/flaticon/beer/flaticon.css';

import './css/plugins.css';
import './css/style.css';
import './css/templete.min.css';
import "aos/dist/aos.css";


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <React.StrictMode>



    <App />

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
