
import pic2 from '../../../images/blog/grid/pic2.webp';
import ecologie from '../../../images/blog/grid/ecologie.webp';
import durable from '../../../images/blog/grid/durable.webp';
import economie from '../../../images/blog/grid/economie.webp';
import security from '../../../images/blog/grid/sécurité.webp';
import sociale from '../../../images/blog/grid/sociale.webp';
import urgence from '../../../images/blog/grid/urgence.webp';






export const datas = [
    {
        id: 0,
        title: " • La Qualité Chez SYPHEMCO",
        pic: ` ${pic2}`,
        text: " La qualité de services chez SYPHEMCO  constitue le centre de nos préoccupations. Nous nous sommes engagés à mettre à disposition tous les moyens et ressources nécessaires pour  l'instauration d'un système de management efficaces couvrant les aspects qualité. Pour consolider et maintenir notre position, nous devons plus que jamais, être soucieux des exigences de nos clients et anticiper à leurs besoins et attentes.",
    },
    {
        id: 1,
        title: " • Sécurité et Santé",
        pic: ` ${security}`,
        text: "Aucune somme d'argent ne vaut une vie. La sécurité et santé au travail paie le plus lorsque chaque employé rentre chez lui en toute sécurité pour retrouver sa famille. La sécurité et santé au travail permet de réduire les coûts dues aux accidents et à la perte de productivité. La sécurité et santé au travail augmente la confiance, le respect, la satisfaction et la fidélité des parties prenantes. Une bonne performance en matière de sécurité et santé au travail augmente nos chances de réussir les projets et de remporter à de nouvelles affaires.",
    },
    {
        id: 2,
        title: " • Se Préparer Aux Situations D'Urgence",
        pic: ` ${urgence}`,
        text: "Nous sommes conscients que nous devons définir toutes les situations d’urgences que nous pouvons rencontrer et planifier comment intervenir et réagir en cas d’accident. ",
    },
    {
        id: 3,
        title: " • Développement Durable",
        pic: ` ${durable}`,
        text: "Face à la pollution qui massacre notre planète, le réchauffement climatique alarmant, les catastrophes naturelles sans précédents, l'épuisement des ressources en eau, les crises financières. Nous sommes conscients que nous devons agir d’une façon responsable quel que soit notre taille, notre position et notre impact.  ",
    },
    {
        id: 4,
        title: " • Responsabilité Economique",
        pic: ` ${economie}`,
        text: "Assurer la viabilité et la rentabilité de l'entreprise, par la création de la valeur, maîtrise des risques et l'optimisation des ressources et des processus.",
    },
    {
        id: 5,
        title: " • Responsabilité Sociale et Sociétale",
        pic: ` ${sociale}`,
        text: "Contribution au progrès social, par l'ouverture sur la société et l'instauration d'un cadre de travail valorisant le capital humain, assurant l'équité et l'égalité des chances et permettant l'épanouissement des talents. ",
    },
    {
        id: 6,
        title: " • Responsabilité Ecologique",
        pic: ` ${ecologie}`,
        text: "Contribuer activement à la préservation des ressources et de l'environnement ; par le recours aux ressources renouvelables et aux matériaux recyclables, la lutte contre le gaspillage et la réduction des effets nuisibles à l'environnement. ",
    },

];