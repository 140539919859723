import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';


import Aos from "aos";

import ScrollToTop from '../../element/ScrollToTop';
import Footer from '../../layout/Footer/Footer';
import Header from '../../layout/Header/Header';
import Portfolio from './Portfolio/Portfolio';

import './Projets.css'



import bg from '../../../images/banner/bnr1.webp';

import { useTranslation } from "react-i18next";




const Projets = ({ lang, SetLang }) => {

    const [t, i18n] = useTranslation('traduction');

    useEffect(() => {
        Aos.init({
            duration: 1500,
            disable: 'mobile',
            once: true,
            mirror: false
        });
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    return (<>

        <Header lang={lang} SetLang={SetLang} />

        <div className="page-content bg-white">
            <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }} >
                <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{t("Projets.Projets")} </h1>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li><Link to="/">{t("Projets.home")}</Link></li>
                                <li> {t("Projets.Projets")} </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Portfolio col="col-md-3" lang={lang} />
        </div>


        <Footer hideContactInfo displayNewsLetter lang={lang} />

        <ScrollToTop className="scroltop style2 radius " />
    </>
    );
}

export default Projets;