import manufacturing from "../../../../images/portfolio/project1/img1.webp";
import iron from "../../../../images/portfolio/project1/img2.webp";
import pipes from "../../../../images/portfolio/project1/img3.webp";
import steel from "../../../../images/portfolio/project1/img4.webp";
import cabine from "../../../../images/portfolio/project1/cabine.jpg";
import cabine2 from "../../../../images/portfolio/project1/cabine_3.jpeg";
import leak_test from "../../../../images/portfolio/project1/leak_test.jpeg";
export const projectContent_fr = [
  {
    image: manufacturing,
    projectName: "Installation Tuyauterie",
  },
  {
    image: cabine,
    projectName: "Construction de cabine",
  },
  {
    image: leak_test,
    projectName: "TEST D'ÉTANCHÉITÉ ET HYDROTEST",
  },
  {
    image: iron,
    projectName: "Installation Tuyauterie",
  },
  {
    image: pipes,
    projectName: "Installation Tuyauterie",
  },
  {
    image: steel,
    projectName: "Installation Tuyauterie",
  },
  {
    image: cabine2,
    projectName: "Construction de cabine",
  },
];
export const projectContent_en = [
  {
    image: manufacturing,
    projectName: "Piping installation",
  },
  {
    image: cabine,
    projectName: "Cabin construction",
  },
  {
    image: leak_test,
    projectName: "LEAK TEST & HYDROTEST",
  },
  {
    image: iron,
    projectName: "Piping installation",
  },
  {
    image: pipes,
    projectName: "Piping installation",
  },
  {
    image: steel,
    projectName: "Piping installation",
  },

  {
    image: cabine2,
    projectName: "Cabin construction",
  },
];
