import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import Footer from '../../layout/Footer/Footer';
import Header from '../../layout/Header/Header';

import Aos from "aos";


import bnr3 from './../../../images/banner/bnr3.webp';
import pic6 from '../../../images/about/pic6.webp';
import bg8 from '../../../images/background/bg8.jpg';
import ScrollToTop from '../../element/ScrollToTop';

import { useTranslation } from "react-i18next";


const Présentation = ({ lang, SetLang }) => {

    const [t, i18n] = useTranslation('traduction');
    useEffect(() => {
        Aos.init({
            duration: 1500,
            disable: 'mobile',
            once: true,
            mirror: false
        });
        i18n.changeLanguage(lang);
    }, [i18n, lang]);
    return (<>
        <Header lang={lang} SetLang={SetLang} />

        <div className="page-content bg-white">
            <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{t("Présentation.Head.présentation")} </h1>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li><Link to={"/"}>{t("Présentation.Head.home")} </Link></li>
                                <li>{t("Présentation.Head.présentation")} </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-block">
                <div className="section-full content-inner bg-white">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 m-b30">
                                <div className="our-story">
                                    <span>{t("Présentation.description.title")}</span>
                                    <h2 className="title"><br />SYPHEMCO<br /><span className="text-primary">{t("Présentation.description.date")}</span></h2>
                                    <h4 className="title">
                                        {t("Présentation.description.présentation")}
                                    </h4>
                                    <p>
                                        {t("Présentation.description.description")}
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                <img src={pic6} className="radius-sm" alt="syphemco" />
                            </div>
                        </div>
                    </div>
                </div>




                <div className="section-full bg-gray content-inner">
                    <div className="container">
                        <div className="section-head text-center">
                            <h2 className="title" id="services" >{t("Présentation.service.title")}</h2>
                            <p>{t("Présentation.service.description")}</p>
                        </div>
                        <div className="section-content row">
                            <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                <div className="icon-bx-wraper" data-name="01">
                                    <div className="icon-lg">
                                        <Link className="icon-cell"><i className="flaticon-robot-arm"></i></Link>
                                    </div>
                                    <div className="icon-content">
                                        <h2 className="dlab-tilte">{t("Présentation.service.data.0.li")}</h2>
                                        <p>{t("Présentation.service.data.0.line")}</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                <div className="icon-bx-wraper" data-name="02">
                                    <div className="icon-lg">
                                        <Link className="icon-cell"><i className="flaticon-fuel-truck"></i></Link>
                                    </div>
                                    <div className="icon-content">
                                        <h2 className="dlab-tilte">{t("Présentation.service.data.1.li")}</h2>
                                        <p>{t("Présentation.service.data.1.line")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                <div className="icon-bx-wraper" data-name="03">
                                    <div className="icon-lg">
                                        <Link to={"/#"} className="icon-cell"><i className="flaticon-engineer-1"></i></Link>
                                    </div>
                                    <div className="icon-content">
                                        <h2 className="dlab-tilte">{t("Présentation.service.data.2.li")}</h2>
                                        <p className="text-capitalize">{t("Présentation.service.data.2.line")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="section-full bg-white content-inner overlay-black-dark bg-img-fix text-white" style={{ backgroundImage: "url(" + bg8 + ")" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sort-title clearfix text-center text-white">
                                    <h4>
                                        {t("Présentation.footer.description")}
                                    </h4>
                                </div>

                                <div className="section-content box-sort-in m-b10 p-b0 button-example m-b30">
                                    <div className="row">
                                        <div className="col-md-4 col-lg-4">
                                            <ul className="list-cup primary reverse rounded">
                                                <li> {t("Présentation.footer.li1")}</li>
                                                <li> {t("Présentation.footer.li2")}</li>
                                                <li> {t("Présentation.footer.li3")}</li>
                                                <li> {t("Présentation.footer.li4")}</li>

                                            </ul>
                                        </div>
                                        <div className="col-md-4 col-lg-4">
                                            <ul className="list-cup primary reverse rounded">
                                                <li> {t("Présentation.footer.li5")}</li>
                                                <li> {t("Présentation.footer.li6")}</li>
                                                <li> {t("Présentation.footer.li7")}</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4 col-lg-4">
                                            <ul className="list-cup primary reverse rounded">
                                                <li> {t("Présentation.footer.li8")}</li>
                                                <li> {t("Présentation.footer.li9")}</li>
                                                <li> {t("Présentation.footer.li10")}</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>






            </div>
        </div>
        <Footer hideContactInfo displayNewsLetter lang={lang} />

        <ScrollToTop className="scroltop style2 radius " />
    </>
    );
}

export default Présentation;