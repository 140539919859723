import pic1 from "../../../../images/blog/grid/civil.webp";
import pic2 from "../../../../images/blog/grid/meca.webp";
import pic3 from "../../../../images/blog/grid/tuyauterie.webp";
import pic4 from "../../../../images/blog/grid/pipeline.webp";
import modularUnit from "../../../../images/about/modular_unit.png";
import photovoltaic from "../../../../images/about/photovoltaic.webp";
import management_services from "../../../../images/about/management_services.jpg";
export const datas = [
  {
    id: 0,
    pic: ` ${pic1}`,
  },
  {
    id: 1,
    pic: ` ${pic2}`,
  },
  {
    id: 2,
    pic: ` ${pic3}`,
  },
  {
    id: 3,
    pic: ` ${pic4}`,
  },
  {
    id: 4,
    pic: ` ${modularUnit}`,
  },
  {
    id: 5,
    pic: ` ${photovoltaic}`,
  },
  {
    id: 6,
    pic: ` ${management_services}`,
  },
];
