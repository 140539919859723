import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import pm1 from "../../images/client-logo/pm.webp";
import chicago from "../../images/client-logo/chicago.webp";
import piper from "../../images/client-logo/piper.webp";
import damla from "../../images/client-logo/damla.webp";
import oswal from "../../images/client-logo/oswal.webp";
import ing from "../../images/client-logo/ing.webp";
import trapsa from "../../images/client-logo/trapsa.webp";
import biltek from "../../images/client-logo/biltek.webp";
import andalou_kimya from "../../images/client-logo/merkez_andalou_kimya.webp";
import omv from "../../images/client-logo/omv.webp";
import sunAntipasti from "../../images/client-logo/sun_antipasti.webp";
import mazarineEnergy from "../../images/client-logo/mazarine_energy.webp";
import amentum from "../../images/client-logo/amentum.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const content = [
  {
    logo: omv,
  },
  {
    logo: sunAntipasti,
  },
  {
    logo: mazarineEnergy,
  },
  {
    logo: amentum,
  },
  {
    logo: pm1,
  },
  {
    logo: chicago,
  },
  {
    logo: piper,
  },
  {
    logo: damla,
  },
  {
    logo: oswal,
  },
  {
    logo: ing,
  },
  {
    logo: trapsa,
  },
  {
    logo: biltek,
  },
  {
    logo: andalou_kimya,
  },
];

class ClientSlider1 extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    const Border = this.props.hideBorder ? "" : "border";
    const padding = this.props.allowPadding ? "p-a25" : "";

    return (
      <>
        <Slider
          {...settings}
          className="client-logo-carousel btn-style-1 icon-2"
        >
          {content.map((item, id) => (
            <div key={id} className="item">
              <div className={`ow-client-logo ${padding}`}>
                <div className={`client-logo ${Border}`}>
                  <Link to="#">
                    <img src={item.logo} alt="syphemco" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}
export default ClientSlider1;
