import React, { useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import Accueil from "./Components/pages/Accueil/Accueil";
import Présentation from "./Components/pages/Présentation/Présentation";


import Projets from './Components/pages/Projets/Projets';
import Contact from './Components/pages/Contact/Contact';
import DeveloppementDurable from './Components/pages/DeveloppementDurable/DeveloppementDurable';
import ScrollToTopRender from "./Components/element/ScrollToTop_render";
import Construction from './Components/pages/Activités/Construction/Construction';
import Approvisionnement from './Components/pages/Activités/Approvisionnement/Approvisionnement';
import Error from "./Components/pages/Error/Error";



import { I18nextProvider } from "react-i18next";
import i18next from "i18next";


import { ToastProvider } from 'react-toast-notifications';

function App() {
  const [lang, SetLang] = useState('fr');


  return (
    <div className="App">

      <Router>
        <ScrollToTopRender />
        <I18nextProvider i18n={i18next}>
          <Switch>

            <Route exact path="/"  >
              <Accueil lang={lang} SetLang={SetLang} />
            </Route>

            <Route path="/presentaion"  >
              <Présentation lang={lang} SetLang={SetLang} />
            </Route>

            <Route exact path="/activites"  >
              <Construction lang={lang} SetLang={SetLang} />
            </Route>

            <Route path="/activites/construction" >
              <Construction lang={lang} SetLang={SetLang} />
            </Route>


            <Route path="/activites/approvisionnement" >
              <Approvisionnement lang={lang} SetLang={SetLang} />
            </Route>


            <Route path={"/projets"} >
              <Projets lang={lang} SetLang={SetLang} />
            </Route>

            <Route path={"/Developpement_durable"} >
              <DeveloppementDurable lang={lang} SetLang={SetLang} />
            </Route>

            <Route path={"/contact"} >
              <ToastProvider placement="top-center">
                <Contact lang={lang} SetLang={SetLang} />
              </ToastProvider>
            </Route>

            <Route component={Error} />

          </Switch>
        </I18nextProvider>

      </Router>

    </div>
  );
}

export default App;


