import image1 from "../../../images/portfolio/project1/img1.webp";

import image3 from "../../../images/portfolio/project1/parking.webp";
import cabine from "../../../images/portfolio/project1/cabine.jpg";
import cabine2 from "../../../images/portfolio/project1/cabine_2.jpeg";
import cabine3 from "../../../images/portfolio/project1/cabine_3.jpeg";
import leak_test from "../../../images/portfolio/project1/leak_test.jpeg";
export const images_fr = [
  {
    id: 0,
    name: "Projets réalisés",
    title: "Construction de cabine proffessionnelle",
    subTitle: "BILTEK",
    imgUrl: ` ${cabine3}`,
  },
  {
    id: 1,
    name: "Projets réalisés",
    title: "Installation Tuyauterie",
    subTitle: "Pipeline",
    imgUrl: ` ${image1}`,
  },

  {
    id: 2,
    name: "Projets réalisés",
    title: "Mise en place d'une barrière automatique",
    subTitle: "Zone frontière TRAPSA",
    imgUrl: ` ${image3}`,
  },
  {
    id: 3,
    name: "Projets réalisés",
    title: "Construction de cabine proffessionnelle",
    subTitle: "BILTEK",
    imgUrl: ` ${cabine}`,
  },
  {
    id: 4,
    name: "Projets réalisés",
    title: "Construction de cabine proffessionnelle",
    subTitle: "BILTEK",
    imgUrl: ` ${cabine2}`,
  },
  {
    id: 5,
    name: "Projets réalisés",
    title: "TEST D'ÉTANCHÉITÉ ET HYDROTEST",
    subTitle: "OMV",
    imgUrl: ` ${leak_test}`,
  },
];

export const images_en = [
  {
    id: 0,
    name: "Projets réalisés",
    title: "Construction de cabine proffessionnelle",
    subTitle: "BILTEK",
    imgUrl: ` ${cabine3}`,
  },
  {
    id: 1,
    name: "FINALISED project",
    title: "Piping installation",
    subTitle: "Pipeline",
    imgUrl: ` ${image1}`,
  },

  {
    id: 2,
    name: "FINALISED project",
    title: "Installation of an automatic barrier",
    subTitle: "TRAPSA border zone",
    imgUrl: ` ${image3}`,
  },
  ,
  {
    id: 3,
    name: "FINALISED project",
    title: "Construction of a professional cabin",
    subTitle: "BILTEK",
    imgUrl: ` ${cabine}`,
  },
  {
    id: 4,
    name: "Projets réalisés",
    title: "Construction de cabine proffessionnelle",
    subTitle: "BILTEK",
    imgUrl: ` ${cabine2}`,
  },
  {
    id: 5,
    name: "Projets réalisés",
    title: "LEAK TEST & HYDROTEST",
    subTitle: "OMV",
    imgUrl: ` ${leak_test}`,
  },
];
