import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { images_fr, images_en } from "../Data";

import Aos from "aos";

import "./Portfolio.css";

// const FILTER_DEFS = {
//   agriculture: image => image.agriculture,
//   chemical: image => image.chemical,
//   mechanical: image => image.mechanical,
//   all: image => image
// };

const getFilterSections = (images) => {
  const filters = ["Tous"];
  // eslint-disable-next-line array-callback-return
  images.map((image) => {
    if (filters.indexOf(image.name) === -1) {
      filters.push(image.name);
    }
  });
  return filters;
};

const filterImages = (filterKey, images) => {
  const list = images.filter((image) =>
    filterKey === "Tous" ? image : image.name === filterKey
  );

  return list;
};

const Portfolio = (props) => {
  const images = props.lang === "fr" ? images_fr : images_en;

  useEffect(() => {
    Aos.init({
      duration: 2000,
      disable: "mobile",
      once: true,
      mirror: false,
    });
  }, []);

  const [state, setState] = useState({
    list: images,
    filterKey: "Tous",
  });

  const { list, filterKey } = state;

  const filteredList = filterImages(filterKey, list);
  const filters = getFilterSections(images);
  const collumnCls = props.col ? props.col : "col-md-3";

  return (
    <div className="content-block">
      <div
        className="section-full content-inner-2 portfolio text-uppercase bg-gray"
        id="portfolio"
      >
        <div className="container">
          <div className="site-filters clearfix center  m-b40">
            {/* FILTERS */}
            <ul className="filters" data-toggle="buttons">
              {filters.map((filter, id) => (
                <li
                  key={id}
                  className="btn"
                  onClick={() => setState({ ...state, filterKey: filter })}
                >
                  <div className="site-button-secondry button-sm radius-xl">
                    <span>{filter}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* FILTERED PORTFOLIO LIST */}
          <div className="portfolio_area">
            <div className="row portfolio-grid">
              {filteredList.map((image, id) => (
                <div key={id} className={collumnCls} data-aos="zoom-in">
                  <div className="dlab-box dlab-gallery-box">
                    <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                      <Link to="/portfolio-details">
                        {" "}
                        <img src={image.imgUrl} alt="syphemco" />{" "}
                      </Link>
                      <div className="overlay-bx">
                        <div className="overlay-icon"></div>
                      </div>
                    </div>
                    <div className="dez-info p-a30 bg-white bg-card">
                      <p className="dez-title m-t0">{image.title}</p>
                      <p>
                        <small>{image.subTitle}</small>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
