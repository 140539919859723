import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer/Footer';


import { homeSliderContent_en, homeSliderContent_fr } from './SliderContent/homeSliderContent'
import { projectContent_en, projectContent_fr } from './SliderContent/projectContent'

import ClientSlider1 from '../../element/ClientSlider';
import ProjectSlider1 from '../../element/ProjectSlider';

import HomeSlider from '../../element/Homeslider'
import DualSlider from '../../element/DualSlider';
import ScrollToTop from '../../element/ScrollToTop';

import { useTranslation } from "react-i18next";

import '../../../css/skin/skin-4.min.css';
import './Accueil.css'
import Aos from "aos";



const bg = require('../../../images/background/cs.jpg');



const Accueil = ({ lang, SetLang }) => {


    const [t, i18n] = useTranslation('traduction');

    useEffect(() => {
        Aos.init({
            duration: 1500,
            disable: 'mobile',
            once: true,
            mirror: false
        });
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    return (
        <>
            <Header lang={lang} SetLang={SetLang} />
            {lang === 'fr'
                ? <HomeSlider

                    data={homeSliderContent_fr}
                    contentWrapperCls="sliderStyle4"
                    btnCls="site-button"
                    btnText={t('Accueil.btn1')}
                    btn2Text={t('Accueil.btn2')}
                    show2Btn
                />
                : <HomeSlider

                    data={homeSliderContent_en}
                    contentWrapperCls="sliderStyle4"
                    btnCls="site-button"
                    btnText={t('Accueil.btn1')}
                    btn2Text={t('Accueil.btn2')}
                    show2Btn
                />
            }


            <div className="page-content bg-white">
                <div className="content-block">
                    <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 text-white">
                                    <h2 className="title">{t('Accueil.Head.title')}</h2>
                                    <p className="m-b0">{t('Accueil.Head.description')}</p>
                                </div>
                                <div className="col-lg-3 d-flex">
                                    <Link to="/contact" className="site-button white align-self-center outline ml-auto radius-xl outline-2 btnhover16 btnhover16">
                                        {t('Accueil.Head.contact')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="section-full content-inner bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                        <div className="container">
                            <div className="on-show-slider">

                                <DualSlider lang={lang} />

                            </div>
                        </div>
                    </div>



                    <div className="section-full content-inner-2 bg-img-fix overlay-black-dark wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="section-head text-white text-center">
                                <h2 className="title">{t('Accueil.projets')}</h2>
                            </div>
                            <div className="row icon-white">
                                <div className="col-lg-12 col-md-12">
                                    {lang === 'fr'
                                        ? <ProjectSlider1 data={projectContent_fr} />
                                        : <ProjectSlider1 data={projectContent_en} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="section-full content-inner-2 bg-gray wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                        <div className="container">
                            <div className="section-head text-black text-center">
                                <h2 className="title text-capitalize">{t("Accueil.footer.title1")}<br />
                                    <span className="text-primary">{t("Accueil.footer.title2")}</span></h2>
                            </div>
                            <ClientSlider1 />
                        </div>
                    </div>
                </div>
            </div>

            <Footer hideContactInfo displayNewsLetter lang={lang} />
            <ScrollToTop className="scroltop style2 radius " />
        </>
    )
}

export default Accueil;



