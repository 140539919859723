import React, { useEffect } from 'react';


import { Link } from 'react-router-dom';

import Aos from "aos";

import ScrollToTop from '../../../element/ScrollToTop';
import Footer from '../../../layout/Footer/Footer';
import Header from '../../../layout/Header/Header';

import './Approvisionnement.css'


import bg from '../../../../images/banner/bnr1.webp';


import { datas, fournitures } from './Data';

import { useTranslation } from "react-i18next";


const Approvisionnement = ({ lang, SetLang }) => {

    const [t, i18n] = useTranslation('traduction');



    useEffect(() => {
        Aos.init({
            duration: 1500,
            disable: 'mobile',
            once: true,
            mirror: false
        });
        i18n.changeLanguage(lang);
    }, [i18n, lang]);
    return (<>
        <Header lang={lang} SetLang={SetLang} />

        <div className="page-content bg-white">
            <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{t('Approvisionnement.Head.li2')}</h1>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li><Link to="/">{t('Approvisionnement.Head.li')}</Link></li>
                                <li> {t('Approvisionnement.Head.li2')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



            <div className="section-full bg-gray content-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className=" clearfix" data-aos="zoom-in-up">
                                <h4>
                                    {t('Approvisionnement.Head.title')}
                                </h4>
                            </div>
                            <div className="dlab-separator-outer ">
                                <div className="dlab-separator text-primary style-icon"><i className="fa fa-leaf"></i></div>
                            </div>

                        </div>
                        <div className="section-content"></div>
                    </div>
                </div>
            </div>





            <div className="section-full bg-white content-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="section-content p-b0">
                                <div className="row">

                                    {fournitures.map((fourniture, id) => (
                                        <div key={id} className="col-lg-4 col-md-6 col-sm-6 m-b30 card-box" data-aos="zoom-in" >
                                            <div className="dlab-box">
                                                <div className="dlab-media dlab-img-overlay12 left"> <img src={fourniture.pic} alt="syphemco" />
                                                    {/* Add 'no-hover' to this div so that ,  the title stay in it's place */}
                                                    <div className="dlab-info-has p-a20  ">
                                                        <div className="dlab-post-title">
                                                            <h5 className="post-title">{t("Approvisionnement.fournitures.title" + fourniture.id)} </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dlab-post-title2">
                                                    <h5 >{t("Approvisionnement.fournitures.title" + fourniture.id)} </h5>
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full bg-gray content-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className=" clearfix" data-aos="zoom-in-up">
                                <h4>
                                    {t("Approvisionnement.Equipement")}
                                </h4>
                            </div>
                            <div className="dlab-separator-outer ">
                                <div className="dlab-separator text-primary style-icon"><i className="fa fa-leaf"></i></div>
                            </div>

                        </div>
                        <div className="section-content"></div>
                    </div>
                </div>
            </div>




            <div className="content-area">
                <div className="container">

                    {datas.map((data, id) => (

                        <div key={id} className="blog-post blog-md clearfix">

                            <div className="dlab-post-title" >
                                <h4 className="post-title" data-aos="fade-right">{t("Approvisionnement.datas." + data.id + ".title")}</h4>
                                <div className="dlab-separator-outer ">
                                    <div className="dlab-separator bg-primary style-skew"></div>
                                </div>
                            </div>
                            <div className="dlab-post-media dlab-img-effect zoom" data-aos="fade-up-right" >
                                <img src={data.pic} alt="syphemco" />
                            </div>
                            <div className="dlab-post-info">
                                <div className="dlab-post-text" data-aos="zoom-in">
                                    <p>{t("Approvisionnement.datas." + data.id + ".text")}</p>
                                </div>

                            </div>
                        </div>
                    ))}


                </div>
            </div>

        </div>

        <Footer hideContactInfo displayNewsLetter lang={lang} />

        <ScrollToTop className="scroltop style2 radius " />

    </>);
}

export default Approvisionnement;